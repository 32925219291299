<template>
    <div class="details_warp">
        <div class="details_content">
            <div id="detailsTop" class="details_top">
                <div class="details_crumbs"><span class="toguide" @click="toguide(modular || details.pressColumn)">公司首页 | {{ modular || details.pressColumn }} | 内容</span></div>
                <div class="details_name">{{ details.pressTitle }}</div>
                <div class="details_other">
                    <span class="author">作者: {{ details.author }}</span><span>发布时间: {{ details.releasetime }}</span>
                </div>
            </div>
            <div class="details_info">
                <div class="content">
                    <div v-html="$xss(details.pressDescribe)"></div>
                    <div class="enclosure" v-if="src">
                      <p class="enclosure_title">附件信息:</p>
                      <pdf class="pdf"
                        v-for="i in numPages"
                        :key="i"
                        ref="pdf"
                        :page="i"
                        style="width: 100%; height: auto;"
                        @num-pages="pageTotalNum=$event"
                        :src="pdfUrl">
                      </pdf>
                      <!-- 分页 -->
                      <!-- <p class="arrow" v-if="pageTotalNum>1">
                        <span @click="changePdfPage(0)" class="turn">
                        上一页
                        </span>
                        {{pageNum}} / {{pageTotalNum}}
                        <span @click="changePdfPage(1)" class="turn">
                        下一页
                        </span>
                      </p> -->
                    </div>
                    <div v-if="imgData">
                      <p class="enclosure_title">附件信息:</p>
                      <a-spin :spinning="spinning">
                        <div v-for="(img, index) in  imgData" :key="index">
                          <img :src="'data:image/png;base64,'+img">
                        </div>
                      </a-spin>
                    </div>
                </div>
            </div>
            <div class="details_btn">
                <div class="btn browse">
                    <div class="btn_warp">
                        <a-icon type="eye" theme="filled"></a-icon>
                    </div>
                    <span>{{ details.viewnum }}</span>
                </div>
                <div class="btn fabulous">
                    <div class="btn_warp" @click="fabulous">
                        <a-icon type="like" theme="filled"></a-icon>
                    </div>
                    <span>{{details.upnum || 0}}</span>
                </div>
                <div class="btn comment">
                    <div class="btn_warp" @click="backComment('#comment')">
                        <a-icon type="aliwangwang" theme="filled"></a-icon>
                    </div>
                    <span>{{commentList.length}}</span>
                </div>
                <div class="btn backTop">
                    <div class="btn_warp" @click="backTop('#detailsTop')">
                        <a-icon type="to-top"></a-icon>
                    </div>
                    <span class="to-top">返回顶部</span>
                </div>
            </div>
        </div>
        <div id="comment" class="details_comment">
            <div class="comment_cotent">
                <div class="comment_warp">
                    <div class="my_comment">
                        <div class="avatar">
                            <img :src="staticDomainURL + userInfo.avatar" alt="">
                        </div>
                        <div class="comment">
                            <a-textarea v-model="myComment" class="textarea" placeholder="写下你的评论" auto-size />
                        </div>
                        <div class="publish" @click="publish">
                            发表
                        </div>
                    </div>
                    <div class="comment_list">
                        <div class="list_title">
                            <div class="title">内容评论</div>
                            <div class="away" @click="retractBtn">收起评论</div>
                        </div>
                        <ul v-if="!retract" class="list_ul">
                            <li class="list_li" v-for="comment in openComment" :key="comment.id">
                                <div class="avatar">
                                    <img :src="staticDomainURL + comment.userheadp" alt="">
                                </div>
                                <div class="comment">
                                    <p class="name">{{comment.username}}</p>
                                    <div class="comment_info">
                                        {{ comment.cotent }}
                                    </div>
                                </div>
                            </li>
                            <div v-if="openComment.length == commentList.length" class="comment_bottom">已到达底部</div>
                        </ul>
                        <!-- <div v-if="openComment.length == commentList.length" class="comment_bottom">已到达底部</div> -->
                        <div class="expand_more" @click="openBtn">展开更多</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import Vue from 'vue'
import { USER_INFO } from "@/store/mutation-types"
import pdf from 'vue-pdf'

const toguideList = {
  '经营专题':'middle_warp',
  '一线故事':'middle_warp',
  '人文软控':'middle_warp',
  '热门文章':'middle_warp',
  '热门视频':'middle_warp',
  '公司制度':'last_warp',
  '党工团资讯':'last_warp',
  '软控报':'last_warp',
  '明星产品':'last_warp',
  '新软控人':'last_warp',
}

export default {
  components: {
    pdf
  },
    data() {
        return {
            toguideList,
            myComment:'',
            retract: false,
            id: this.$route.query.id,
            details: {},
            commentList: [],
            openComment: [],
            openNum: 1,
            userInfo: Vue.ls.get(USER_INFO),
            pdfUrl: '',
            src:'',
            srcUrl: '',
            pageNum:1,
            imgData: [],
            pageTotalNum:1,
            spinning: false,
            model: {},
            numPages:1,
            staticPdfUrl:window._CONFIG['staticPdfUrl'],
            staticDomainURL: window._CONFIG['staticDomainURL'],
            url: {
              download:"/zmeResource/zmeResourceCenter/download"
            }
        }
    },
    created() {
        this.getDetails()
        this.getcommentList()
    },
    computed: {
      modular() {
        return this.$route.query.model
      }
    },
    // mounted(){
    //   this.loadPdfHandler()
    // },
    methods: {
      async loadPdfHandler () {
        this.pdfUrl = ''
        //src为你服务器上存放pdf的路径
        if(this.src.indexOf('resourceCenter')!= -1) {
        this.pdfUrl = pdf.createLoadingTask(this.staticPdfUrl+this.src);
        this.pdfUrl.promise.then(pdf => {
          this.numPages = pdf.numPages
        })
        return
      }
        if(this.src.indexOf("home")!=-1){
          this.pdfUrl = pdf.createLoadingTask(this.staticPdfUrl+this.src);
          this.pdfUrl.promise.then(pdf => {
          this.numPages = pdf.numPages
        })
        }else {
          this.pdfUrl = pdf.createLoadingTask(this.staticDomainURL+this.src);
          console.log('this.pdfUrl: ', this.pdfUrl);
          this.pdfUrl.promise.then(pdf => {
            this.numPages = pdf.numPages
          })
        }
      },
        toguide(value) {
          let select  = toguideList[value]
          localStorage.setItem('id', select)
          this.$router.push('/home')
        },
        getDetails() {
          if(this.$route.query.model === '公司制度') {
            console.log('公司制度')
            let id  = this.$route.query.id
            getAction('/mesnac/zmeResource/zmeResourceCenter/selectFileById', {fileId: id}).then(res => {
              if(res.success) {
                var dates = res.result.createTime
                var times = new Date(dates).getTime();
                if(times<new Date('2021-04-14 18:00:00').getTime()){
                  this.src = res.result.filepath;
                }else {
                  this.src = res.result.viewpath;
                }
              }
            }).finally(() => [
                this.loadPdfHandler()
            ])
          }else if (this.$route.query.model === '技术标准'){
              console.log('技术标准')
              let id = this.$route.query.id
              getAction('/mesnac/zmeResource/zmeResourceCenter/sel_Res', {Fileid: id,userid: this.userInfo.id}).then(res => {
                  if(res.success) {
                      console.log(res)
                      if (res.result === 0){
                          this.$message.warn('您没有查看此文件的权限')
                          return
                      }else {
                          getAction('/mesnac/zmeResource/zmeResourceCenter/selectFileById', {fileId: id}).then(res => {
                              if(res.success) {
                                  var dates = res.result.createTime
                                  var times = new Date(dates).getTime();
                                  if(times<new Date('2021-04-14 18:00:00').getTime()){
                                      this.src = res.result.filepath;
                                  }else {
                                      this.src = res.result.viewpath;
                                  }
                              }
                          }).finally(() => [
                              this.loadPdfHandler()
                          ])
                      }
                  }
              })
          }else {
            let id  = this.$route.query.id
            getAction('/mesnac/zmap/zmaPrescenter/getViewById', { id: id}).then(res => {
                if (res.success) {
                    let details = res.result
                    if(details.pressDescribe) {
                      details.pressDescribe = details.pressDescribe.replace(/<video/g, '<video controlslist="nodownload"')
                    }
                    console.log('details: ', details);
                    this.details = details
                    console.log('this.details: ', this.details)
                    this.src = res.result.accessory
                }
            }).finally(() => {
              if(this.src) {
                this.loadPdfHandler()
              }
            })
          }

        },
        loadImg() {
          this.spinning = true
          postAction('/mesnac/sys/trans/import',{outFile: this.model.filepath }).then(res => {
            this.imgData = res
            this.spinning = false
          })
        },
        // 获取评论列表
        getcommentList() {
            getAction('/mesnac/zmap/zmaPrescenter/getCommentByParentid', {parentid: this.id, column: 'createTime', order: 'desc',}).then(res => {
                if (res.success) {
                    this.commentList = res.result
                    this.openComment = this.commentList.slice(0,3)
                }
            })
        },
        // 返回顶部
        backTop(id) {
            document.querySelector(id).scrollIntoView(true)
        },
        // 跳转评论
        backComment(id) {
            document.querySelector(id).scrollIntoView(true)
        },
        // 点赞
        fabulous() {
            // let data = {id: this.id }
            // let jsonData = JSON.stringify(data)
            getAction(`/mesnac/zmap/zmaPrescenter/upNumAdd`, {id: this.id }).then(res => {
                if (res.success) {
                    this.details.upnum ++
                }
            })
        },
        // 发表评论
        publish() {
            if(!this.myComment) {
                this.$message.warn('请先填写评论后再发表')
                return
            }
            let zmaPresscomment = {
                cotent: this.myComment,
                username: this.userInfo.realname,
                userheadp: this.userInfo.avatar,
                parentid: this.id
            }
            console.log(JSON.stringify(zmaPresscomment))
            getAction('/mesnac/zmap/zmaPrescenter/commentAdd', {zemStr:JSON.stringify(zmaPresscomment)}).then(res=> {
                if (res.success) {
                    this.getcommentList()
                    this.myComment = ''
                    this.openNum = 1
                } else {
                    this.$message.warn(res.message)
                }
            })
        },
        // 收起评论
        retractBtn() {
            this.retract = true
        },
        // 展开
        openBtn () {
            this.retract = false
            this.openNum ++
            let num = 3*this.openNum
            this.openComment = this.commentList.slice(0, num)
        },
        // pdf 翻页
        changePdfPage (val) {
          // console.log(val)
          if (val === 0 && this.pageNum > 1) {
            this.pageNum--
            // console.log(this.currentPage)
          }
          if (val === 1 && this.pageNum < this.pageTotalNum) {
            this.pageNum++
            // console.log(this.currentPage)
          }
        },
        // pdf 首次加载
        // loadPdfHandler() {
        //   this.pageNum = 1
        // }
    }
}
</script>
<style lang="less">
.details_warp{
    width: 100%;
    // height: 1250px;
    padding-top: 40px;
    // background: url("~@/assets/img/home_bg.png") no-repeat top center;
    background: #f6f6f6;
    background-size: 100%;
    .details_content{
        width: 1500px;
        // height: 1000px;
        margin: 0 auto;
        background: #fff;
        position: relative;
        .details_top{
            padding-top: 15px;
            padding-bottom: 45px;
            margin-left: 33px;
            .details_crumbs{
                font-size: 14px;
                color: #101010 10000%;
                .toguide {
                  cursor: pointer;
                  text-decoration: underline;
                }
            }
            .details_name {
                margin-top: 35px;
                font-size: 25px;
                color: #101010;
            }
            .details_other{
                margin-top: 20px;
                font-size: 16px;
                color: rgba(16, 16, 16, 100);
                .author{
                    margin-right: 60px;
                }
            }
        }
        .details_info{
            width: 100%;
            border: 1px solid rgba(187, 187, 187, 100);
            padding-top: 40px;
            padding-bottom: 70px;
            .content {
                width: 1200px;
                margin: 0 auto;
                img{
                  width: 85%;
                  margin: 0 auto;
                  height: 100%;
                }
                video{
                  width: 85%;
                  margin-left: 7%;
                  height: 100%;
                  max-height: 500px;
                }
                video::-internal-media-controls-download-button {
                    display:none;
                }
                video::-webkit-media-controls-enclosure {
                    overflow:hidden;
                }
                /deep/ video::-webkit-media-controls-panel {
                    width: calc(100% + 30px);
                }
                /deep/p{
                  font-size: 18px;
                }
                .enclosure {
                  .enclosure_title{
                    font-size: 22px;
                    font-weight: 600;
                  }
                  .arrow{
                    .turn{
                      cursor: pointer;
                    }
                  }
                }
            }
        }
        .details_btn{
            width: 60px;
            height: 440px;
            position: fixed;
            top: 50%;
            left: 100px;
            transform: translateY(-50%);
            .btn {
                width: 100%;
                text-align: center;
                color: #101010 10000%;
                font-size: 18px;
                margin-bottom: 20px;
                .btn_warp{
                    cursor: pointer;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    overflow: hidden;
                    text-align: center;
                    line-height: 66px;
                    margin-bottom: 8px;
                    i{
                        color: #fff;
                        font-size: 25px;
                    }
                }
            }
            .browse{
                .btn_warp{
                    background: #5C5C5A;
                }
            }
            .fabulous{
                .btn_warp{
                    background: #BC0039;
                }
            }
            .comment{
                .btn_warp{
                    background: #BD2979;
                }
            }
            .backTop{
                position: relative;
                .btn_warp{
                    background: #F4A409;
                }
                .to-top{
                    position: absolute;
                    width: 80px;
                    left: -10px;
                    display: inline-block;
                }
            }

        }
    }
    .details_comment {
        width: 1500px;
        margin: 0 auto;
        border: 1px solid rgba(187, 187, 187, 100);
        background: #F6F6F6 10000%;
        padding-top: 40px;
        padding-bottom: 25px;
        .comment_cotent{

            .comment_warp{
                margin-left: 20px;
                margin-right: 20px;
                .my_comment{
                    display: flex;
                    .avatar{
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 23px;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .comment{
                        width: 1350px;
                        margin-top: 4px;
                        .textarea{
                            line-height: 30px;
                            font-size: 14px;
                            color: #101010 10000%;
                            background: #E9E9E9 10000%;
                            &::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
                                color:#101010;
                                font-size: 14px;
                            }
                        }
                    }
                    .publish{
                        width: 50px;
                        text-align: center;
                        cursor: pointer;
                        line-height: 50px;
                        font-size: 14px;
                        &:hover{
                            color: #BC0039;
                        }
                    }
                }
                .comment_list{
                    padding-top: 50px;
                    .list_title {
                        display: flex;
                        justify-content: space-between;
                        .title {
                            color: rgba(16, 16, 16, 100);
                            font-size: 14px;
                        }
                        .away {
                            cursor: pointer;
                            color: rgba(37, 38, 41, 56);
                            font-size: 14px;
                        }

                    }
                    .list_ul{
                        margin-top: 45px;
                        list-style: none;
                        padding: 0px;
                        .list_li {
                            display: flex;
                            margin-bottom: 40px;
                            .avatar{
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                overflow: hidden;
                                margin-right: 23px;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .comment{
                                width: 1400px;
                                margin-top: 4px;
                                .name{
                                    margin:0px !important;
                                    font-size: 14px;
                                }
                                .comment_info{
                                    margin-top: 7px;
                                    color: rgba(0, 0, 0, 1);
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    .comment_bottom{
                        width: 1500px;
                        text-align: center;
                        font-size: 12px;
                        color:#aaa;
                    }
                    .expand_more{
                        text-align: center;
                        width: 1500px;
                        height: 40px;
                        line-height: 40px;
                        color: #F1F1F1 10000%;
                        font-size: 14px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>


